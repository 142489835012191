import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Button = ({
  type = 'button',
  name,
  id,
  additionalClass,
  text,
  onClickHandler,
  children,
  disabled,
  cypress,
  stat
}) => {
  const clickHandler = onClickHandler || null;
  const classes = cn('btn', additionalClass);

  return (
    <button
      type={type}
      name={name}
      id={id}
      className={classes}
      onClick={clickHandler}
      disabled={disabled}
      data-cy={cypress}
      data-stat={stat}
    >
      <span className="btn__label">
        {children}
        {text}
      </span>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  stat: PropTypes.string,
  additionalClass: PropTypes.string,
  text: PropTypes.string,
  onClickHandler: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node]),
  disabled: PropTypes.bool,
  cypress: PropTypes.string
};

export default Button;
