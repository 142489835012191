import React from 'react';
import PropTypes from 'prop-types';

const NewRadioButton = ({
  data,
  name,
  color,
  cypress,
  currentValue,
  onChangeHandler
}) => (
  <label className="new-search-radio-container" data-cy="radio-container">
    <input
      type="radio"
      name={name}
      value={data.id}
      onChange={onChangeHandler}
      data-cy={`${cypress}-${data.id}`}
      checked={currentValue === data.id}
      className="new-search-radio input-hidden"
    />
    <div
      data-cy={cypress}
      className="new-search-radio__text"
      style={{ color: currentValue === data.id ? color : '#2f1f19' }}
    >
      {data.name}
    </div>
    <span className="new-search-radio__mark-container">
      <span className="new-search-radio__mark" />
    </span>
  </label>
);

NewRadioButton.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  color: PropTypes.string,
  cypress: PropTypes.string,
  currentValue: PropTypes.string,
  onChangeHandler: PropTypes.func
};

export default NewRadioButton;
