import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getDataForTransformationsQuery } from '../../../../queries';
import CloseIcon from '../../../../public/static/images/new_search/icons/icon-reset.svg';
import Icon from '../../../Icon';
import NewRadioButton from '../../../NewRadioButton';
import NumberField from '../NumberField';
import Button from '../../../Button';
import { getValueFromObject, toQueryString } from '../../../../utils/helpers';
import { setSearchSort } from '../../../../actions';

export const sortingVariants = t => [
  {
    id: '1',
    name: t('new_search.sort.by_date')
  },
  {
    id: '2',
    name: t('new_search.sort.by_area_asc')
  },
  {
    id: '3',
    name: t('new_search.sort.by_price_asc')
  },
  {
    id: '4',
    name: t('new_search.sort.by_price_desc')
  }
];

const NewSorting = ({ hide }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const currentSort = useSelector(state => state.searchForm.currentSortType);
  const { data } = useQuery(getDataForTransformationsQuery, {
    fetchPolicy: 'cache-only'
  });

  const onChangeRadioHandler = e => {
    const { search, ...queryObj } = router.query;
    const { url, ...newQueryObj } = queryObj;

    const query = (concatSign = '?') => {
      newQueryObj.sorting = getValueFromObject(
        data.sort,
        e.target.value,
        'params'
      );

      return !Object.keys(newQueryObj).length
        ? ''
        : `${concatSign}${toQueryString(newQueryObj)}`;
    };

    const href = `/[...search]?url=${search.join('/')}${query('&')}`.replace(
      /\/\//g,
      '/'
    );
    const as = `/${search.join('/')}${query()}`.replace(/\/\//g, '/');

    dispatch(setSearchSort(e.target.value));
    router.push(href, as);
    hide();
  };

  return (
    <div>
      {sortingVariants(t).map(sorting => (
        <NewRadioButton
          cypress="city"
          data={sorting}
          key={sorting.id}
          name="search-city"
          currentValue={currentSort || '1'}
          onChangeHandler={onChangeRadioHandler}
        />
      ))}
    </div>
  );
};

NewSorting.propTypes = {
  hide: PropTypes.func
};

const NewPrice = ({ children, onSubmit }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById('new-search-form-price-from').focus();
    document.getElementById('new-search-form-price-from').click();
  }, []);

  return (
    <div className="filter-modal__wrapper">
      <p className="filters__secondary-text">{t('search.form.price')}</p>
      <div className="filters__fields-container">
        <NumberField
          name="price_from"
          id="new-search-form-price-from"
          placeholder={t('search.form.from')}
        />
        <NumberField
          name="price_to"
          id="new-search-form-price-to"
          placeholder={t('search.form.to')}
        />
      </div>
      <Button
        onClickHandler={onSubmit}
        additionalClass="filter-modal__apply-btn"
      >
        {children}
      </Button>
    </div>
  );
};

NewPrice.propTypes = {
  children: PropTypes.string,
  onSubmit: PropTypes.func
};

const NewArea = ({ children, onSubmit }) => {
  const { t } = useTranslation();
  const { values } = useFormState();
  const isLand = values.category_id === '9';

  useEffect(() => {
    document.getElementById('new-search-form-area-from').focus();
    document.getElementById('new-search-form-area-from').click();
  }, []);

  return (
    <div className="filter-modal__wrapper">
      <p className="filters__secondary-text">
        {t('search.form.area')}
        <span> </span>
        {isLand ? t('search.form.units.acres') : t('search.form.units.metres')}
      </p>
      <div className="filters__fields-container">
        <NumberField
          name="area_from"
          id="new-search-form-area-from"
          placeholder={t('search.form.from')}
        />
        <NumberField
          name="area_to"
          id="new-search-form-area-to"
          placeholder={t('search.form.to')}
        />
      </div>
      <Button
        onClickHandler={onSubmit}
        additionalClass="filter-modal__apply-btn"
      >
        {children}
      </Button>
    </div>
  );
};

NewArea.propTypes = {
  children: PropTypes.string,
  onSubmit: PropTypes.func
};

const buildingType = t => [
  {
    id: '2',
    name: t('new_search.new_building')
  },
  {
    id: '3',
    name: t('new_search.secondary_building')
  }
];

const NewBuildingType = ({ children, onSubmit }) => {
  const { t } = useTranslation();
  const { change, getState } = useForm();

  const onChangeRadioHandler = e => {
    const categoryId = e.target.value;
    change('category_id', categoryId);
  };

  return (
    <div className="">
      {buildingType(t).map(type => (
        <NewRadioButton
          key={type.id}
          data={type}
          onChangeHandler={onChangeRadioHandler}
          currentValue={getState().values?.category_id}
          name="category_id"
        />
      ))}
      <Button
        onClickHandler={onSubmit}
        additionalClass="block-with-margin filter-modal__apply-btn"
      >
        {children}
      </Button>
    </div>
  );
};

NewBuildingType.propTypes = {
  children: PropTypes.string,
  onSubmit: PropTypes.func
};

const configs = {
  sorting: {
    title: 'search.buttons.sort',
    Component: NewSorting
  },
  price: {
    title: 'new_search.price',
    Component: NewPrice
  },
  area: {
    title: 'new_search.area',
    Component: NewArea
  },
  buildingType: {
    title: 'search.form.building_type_group.title',
    Component: NewBuildingType
  }
};

const FilterModal = ({ hide, isOpen, modalConfig }) => {
  const { t } = useTranslation();
  const { submit, change } = useForm();

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    document.body.style.position = isOpen ? 'fixed' : 'unset';
    document.body.style.height = isOpen ? '100dvh' : 'unset';
    document.body.style.width = isOpen ? '100vw' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.body.style.height = 'unset';
      document.body.style.width = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;
  const content = configs[modalConfig];

  const onReset = () => {
    switch (modalConfig) {
      case 'price': {
        change('price_from', '');
        change('price_to', '');
        hide();
        break;
      }
      case 'area': {
        change('area_from', '');
        change('area_to', '');
        hide();
        break;
      }
      case 'buildingType': {
        change('category_id', '1');
        hide();
        break;
      }
      default:
        hide();
    }
  };

  const onSubmit = () => {
    submit();
    hide();
  };

  return (
    <>
      <div
        tabIndex={0}
        role="button"
        className="filter-modal__overlay"
        onKeyDown={onReset}
        onClick={onReset}
      />
      <div className="filter-modal__position-box">
        <div className="filter-modal">
          <div className="filter-modal__header">
            {t(content.title)}
            <button
              className="filter-modal__header__close-btn"
              onClick={onReset}
            >
              <Icon IconComponent={CloseIcon} indent={false} />
            </button>
          </div>
          <div className="filter-modal__content">
            <content.Component onSubmit={onSubmit} hide={hide}>
              {t('search.form.show_ads')}
            </content.Component>
          </div>
        </div>
      </div>
    </>
  );
};

FilterModal.propTypes = {
  hide: PropTypes.func,
  isOpen: PropTypes.bool,
  modalConfig: PropTypes.string
};

export default FilterModal;
