import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Icon from '../../../Icon';
import CloseIcon from '../../../../public/static/images/new_search/icons/icon-close.svg';

const NewChips = ({
  id,
  icon,
  text,
  type,
  name,
  stat,
  value,
  style,
  dirty,
  onClick,
  iconSize,
  storedValue,
  borderRadius,
  resetHandler,
  cypress
}) => {
  const active =
    type === 'checkbox'
      ? storedValue?.includes(value)
      : // eslint-disable-next-line
      (value !== undefined) && (value === storedValue);

  return (
    <label
      id={id}
      className={`chips-container${active ? ' chips-active' : ''}`}
      style={{ borderRadius, ...style }}
    >
      <div
        tabIndex={0}
        role="button"
        data-stat={stat}
        onClick={onClick}
        onKeyPress={onClick}
      >
        {type !== 'button' && (
          <Field
            name={name}
            component="input"
            type={type}
            className="display-none"
            value={value === undefined ? '' : value}
          />
        )}
        {icon && (
          <Icon
            IconComponent={icon}
            width={iconSize}
            height={iconSize}
            color="#FFF"
          />
        )}
        <span className="chips__text" data-cy={cypress}>
          {text}
        </span>
      </div>
      {resetHandler && dirty && (
        <div
          tabIndex={0}
          role="button"
          onClick={resetHandler}
          onKeyPress={resetHandler}
          className="chips__reset"
        >
          <Icon
            width={11}
            height={11}
            indent={false}
            IconComponent={CloseIcon}
          />
        </div>
      )}
    </label>
  );
};

NewChips.propTypes = {
  icon: PropTypes.func,
  id: PropTypes.string,
  cypress: PropTypes.string,
  stat: PropTypes.string,
  text: PropTypes.string,
  dirty: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  iconSize: PropTypes.number,
  borderRadius: PropTypes.string,
  resetHandler: PropTypes.func,
  type: PropTypes.oneOf(['button', 'radio', 'checkbox']),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array
  ]),
  storedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array
  ])
};

NewChips.defaultProps = {
  borderRadius: '100px',
  iconSize: 20
};

export default NewChips;
