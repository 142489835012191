import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';
import NewChips from '../common/NewChips';
import PasswordIcon from '../../../public/static/images/new_search/icons/icon-password.svg';
import CalendarIcon from '../../../public/static/images/new_search/icons/icon-calendar.svg';
import MoonIcon from '../../../public/static/images/new_search/icons/icon-moon.svg';
import { setCurrentState } from '../../../actions';

const linkStyles = { padding: '8px 10px', color: '#2f1f19' };

const SelectTabs = ({ change, values, submit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div
      className={classNames(
        'new-search__chips-wrapper',
        'new-search__tabs-wrapper'
      )}
    >
      <Link
        id="buy-link"
        as="/alqi-satqi"
        className="new-search__link"
        href="/[...search]?url=/alqi-satqi"
      >
        <NewChips
          type="button"
          style={linkStyles}
          icon={PasswordIcon}
          borderRadius="14px"
          text={t('new_search.buy')}
          stat="new-search-buy-button"
          onClick={() => {
            change('category_id', undefined);
            change('paid_daily', undefined);
            change('leased', 'false');
            dispatch(
              setCurrentState({
                ...values,
                leased: 'false',
                category_id: undefined,
                paid_daily: undefined
              })
            );
            submit();
          }}
        />
      </Link>
      <Link
        id="rent-link"
        as="kiraye?paid_daily=false"
        className="new-search__link"
        href="/[...search]?url=/kiraye?paid_daily=false"
      >
        <NewChips
          type="button"
          style={linkStyles}
          icon={CalendarIcon}
          borderRadius="14px"
          text={t('new_search.rent')}
          stat="new-search-rent-button"
          onClick={() => {
            change('category_id', undefined);
            change('leased', 'true');
            change('paid_daily', ['false']);
            dispatch(
              setCurrentState({
                ...values,
                leased: 'true',
                category_id: undefined,
                paid_daily: ['false']
              })
            );
            submit();
          }}
        />
      </Link>
      <Link
        id="paid_daily-link"
        as="kiraye?paid_daily=true"
        className="new-search__link"
        href="/[...search]?url=/kiraye?paid_daily=true"
      >
        <NewChips
          type="button"
          icon={MoonIcon}
          style={linkStyles}
          borderRadius="14px"
          text={t('search.form.paid_daily')}
          stat="new-search-paid-daily-button"
          onClick={() => {
            change('category_id', undefined);
            change('leased', 'true');
            change('paid_daily', ['true']);
            dispatch(
              setCurrentState({
                ...values,
                leased: 'true',
                category_id: undefined,
                paid_daily: ['true']
              })
            );
            submit();
          }}
        />
      </Link>
    </div>
  );
};

SelectTabs.propTypes = {
  sort: PropTypes.string,
  values: PropTypes.object,
  change: PropTypes.func,
  submit: PropTypes.func
};

export default SelectTabs;
