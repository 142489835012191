import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NewChips from '../common/NewChips';
import SortIcon from '../../../public/static/images/new_search/icons/icon-sort.svg';
import FilterModal from '../common/FilterModal';
import { setCurrentState } from '../../../actions';

const ModalsWithFilters = ({ sort, values, change, submit, hasCategory }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalConfig, setModalConfig] = useState();
  const categoryFlat = ['1', '2', '3'].includes(values.category_id);
  const currentState = useSelector(state => state.searchForm.currentState);

  const hideModal = () => setModalConfig(null);

  useEffect(() => {
    dispatch(
      setCurrentState({
        ...currentState,
        isHiddenHeader: true
      })
    );
    return () => {
      dispatch(
        setCurrentState({
          ...currentState,
          isHiddenHeader: false
        })
      );
    };
  }, []);

  const onlyPriceTo = values.price_to
    ? t('search.search_title_service.to', { to: values.price_to, unit: 'AZN' })
    : t('new_search.price');
  const onlyPriceFrom = values.price_from
    ? t('search.search_title_service.from', {
        from: values.price_from,
        unit: 'AZN'
      })
    : onlyPriceTo;
  const price =
    values.price_from && values.price_to
      ? `${values.price_from} - ${values.price_to} AZN`
      : onlyPriceFrom;

  const metres = t('search.search_title_service.units.metres');
  const acres = t('search.search_title_service.units.acres');
  const isLand = values.category_id === '9';
  const unit = isLand ? acres : metres;
  const onlyAreaTo = values.area_to
    ? t('search.search_title_service.to', { to: values.area_to, unit })
    : t('new_search.area');
  const onlyAreaFrom = values.area_from
    ? t('search.search_title_service.from', { from: values.area_from, unit })
    : onlyAreaTo;
  const area =
    values.area_from && values.area_to
      ? `${values.area_from} - ${values.area_to} ${unit}`
      : onlyAreaFrom;

  const secondaryOrEmpty =
    values.category_id === '3'
      ? t('new_search.secondary_building')
      : t('search.form.building_type_group.title');
  const buildingType =
    values.category_id === '2'
      ? t('new_search.new_building')
      : secondaryOrEmpty;

  return (
    <div
      className="new-search__chips-wrapper"
      style={hasCategory ? { marginTop: '10px' } : {}}
    >
      <NewChips
        text={sort}
        iconSize={20}
        type="button"
        icon={SortIcon}
        style={{ padding: '8px 15px' }}
        stat="new-search-sorting-modal"
        onClick={() => setModalConfig('sorting')}
      />
      <NewChips
        type="button"
        text={price}
        dirty={values.price_from || values.price_to}
        resetHandler={() => {
          change('price_from', '');
          change('price_to', '');
          submit();
        }}
        stat="new-search-price-modal"
        onClick={() => setModalConfig('price')}
      />
      {categoryFlat && (
        <NewChips
          type="button"
          dirty={values.category_id === '2' || values.category_id === '3'}
          resetHandler={() => {
            change('category_id', '1');
            submit();
          }}
          text={buildingType}
          onClick={() => setModalConfig('buildingType')}
        />
      )}
      <NewChips
        type="button"
        text={area}
        dirty={values.area_from || values.area_to}
        stat="new-search-area-modal"
        resetHandler={() => {
          change('area_from', '');
          change('area_to', '');
          submit();
        }}
        onClick={() => setModalConfig('area')}
      />
      <FilterModal
        hide={hideModal}
        isOpen={!!modalConfig}
        modalConfig={modalConfig}
      />
    </div>
  );
};

ModalsWithFilters.propTypes = {
  sort: PropTypes.string,
  change: PropTypes.func,
  submit: PropTypes.func,
  values: PropTypes.object,
  hasCategory: PropTypes.bool
};

export default ModalsWithFilters;
